@use 'design-tokens' as *;
@use 'utilities' as *;

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// Warning: ViewEncapsulation is disabled in this component
// Any styles defined here will affect the entire application
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

.glxy-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .glxy-empty-state-hero {
    max-width: 360px;
    margin-bottom: $spacing-4;

    &:empty {
      display: none;
    }

    img {
      display: block;
      max-width: 100%;
    }

    mat-icon {
      display: block;
      width: 40px;
      height: 40px;
      font-size: 40px;
      color: $tertiary-font-color;
    }
  }

  .glxy-empty-state-title {
    @include text-preset-2;
    display: block;
    margin: 0 0 $spacing-2;
    color: $primary-font-color;
  }

  .glxy-empty-state-actions {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    button,
    [mat-button],
    [mat-stroked-button],
    [mat-flat-button] {
      margin: $spacing-1;

      @media only screen and (max-width: $media--phone-minimum) {
        width: 100%;
      }
    }
  }

  .glxy-empty-state-footnote {
    @include text-preset-5;
    display: block;
    margin-top: $spacing-2;
    color: $secondary-font-color;
  }
}

.glxy-empty-state__content {
  max-width: 560px;

  p {
    margin: 0 0 $spacing-3;
    color: $secondary-font-color;
  }
}

.glxy-empty-state--width-constraint .glxy-empty-state__content {
  max-width: 360px;
}

.glxy-empty-state--small {
  @include text-preset-5;

  .glxy-empty-state-hero {
    margin-bottom: $spacing-1;
  }

  .glxy-empty-state-title {
    @include text-preset-4--bold;
    margin-bottom: $spacing-1;
  }

  p {
    margin-bottom: $spacing-1;
  }
}
