$atlas_navbar--tooltip-horizontal-padding: 8px;
$atlas_navbar--tooltip-max-width: 250px;
$atlas_navbar--tooltip-margin: 14px;

$atlas_navbar--tooltip-handset-horizontal-padding: 16px;
$atlas_navbar--tooltip-handset-margin: 24px;

$atlas_navbar--tooltip-target-height: 22px;
$atlas_navbar--tooltip-font-size: 10px;
$atlas_navbar--tooltip-vertical-padding: ($atlas_navbar--tooltip-target-height - $atlas_navbar--tooltip-font-size) * 0.5;

$atlas_navbar--tooltip-handset-target-height: 30px;
$atlas_navbar--tooltip-handset-font-size: 14px;
$atlas_navbar--tooltip-handset-vertical-padding: (
    $atlas_navbar--tooltip-handset-target-height - $atlas_navbar--tooltip-handset-font-size
  ) * 0.5;

.atlas_navbar--tooltip-panel {
  // The overlay reference updates the pointer-events style property directly on the HTMLElement
  // depending on the state of the overlay. For tooltips the overlay panel should never enable
  // pointer events. To overwrite the inline CSS from the overlay reference `!important` is needed.
  pointer-events: none !important;
}

// from @angular/cdk
//
// Applies styles for users in high contrast mode. Note that this only applies
// to Microsoft browsers. Chrome can be included by checking for the `html[hc]`
// attribute, however Chrome handles high contrast differently.
//
// @param target Which kind of high contrast setting to target. Defaults to `active`, can be
//    `white-on-black` or `black-on-white`.
@mixin cdk-high-contrast($target: active) {
  @media (-ms-high-contrast: $target) {
    @content;
  }
}

.atlas_navbar--tooltip {
  background: rgba(97, 97, 97, 0.9);
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: $atlas_navbar--tooltip-font-size;
  padding-top: $atlas_navbar--tooltip-vertical-padding;
  padding-bottom: $atlas_navbar--tooltip-vertical-padding;
  color: white;
  border-radius: 4px;
  margin: $atlas_navbar--tooltip-margin;
  max-width: $atlas_navbar--tooltip-max-width;
  padding-left: $atlas_navbar--tooltip-horizontal-padding;
  padding-right: $atlas_navbar--tooltip-horizontal-padding;
  overflow: hidden;
  text-overflow: ellipsis;

  @include cdk-high-contrast {
    outline: solid 1px;
  }
}

.atlas_navbar--tooltip-handset {
  margin: $atlas_navbar--tooltip-handset-margin;
  padding-left: $atlas_navbar--tooltip-handset-horizontal-padding;
  padding-right: $atlas_navbar--tooltip-handset-horizontal-padding;
  font-size: $atlas_navbar--tooltip-handset-font-size;
  padding-top: $atlas_navbar--tooltip-handset-vertical-padding;
  padding-bottom: $atlas_navbar--tooltip-handset-vertical-padding;
}
